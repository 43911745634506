<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import MetaDataForm from "./meta-data-form.vue";
import api from '@/services/secureHttps';
import Rules from '@/components/metadata/rules/rules.vue';
import Spinner from "@/components/spinner";

export default {
    components: {
        Layout,
        PageHeader,
        MetaDataForm,
        Rules,
        Spinner
    },
    props: {
        websiteId: {
            type: Number,
            required: true
        },
        pageId: {
            type: Number,
            required: true
        },
        path: {
            type: String,
            required: true
        },
        featureId: {
            type: Number,
            required: false
        }
    },

    emits: ['addSubFeature'],

    data() {
        return {
            title: "Meta Data",
            items: [],
            features: [],
            selectedFeature: null,
            // remember the forms already filled out
            forms: [
                { id: '', groups: [], feature: null }
            ],
            currentForm: { featureId: null, groups: [] },
            rulesFieldData: [],
            selectedFeatureId: this.featureId,
            isLoading: false
        };
    },

    created() {
        this.fetchFeatureList();
    },

    methods: {
        async fetchFeatureList() {
            api.websiteFeatureList()
                .then(response => {
                    console.log("FEATURES: ", response.data);
                    this.features = response.data.features;

                    if (typeof this.featureId !== 'undefined') {
                        this.selectedFeature = this.features.find(e => e.id === this.featureId);

                        this.fetchMetaDataForm();
                    }

                })
                .catch(e => {
                    console.log(e);
                })
        },

        onFeatureChanged(e) {
            let selectedFeatureId = Number(e.target.value);

            if (isNaN(selectedFeatureId)) {
                this.currentFeature = { id: '', groups: {} };
                return
            }

            this.selectedFeature = this.features.find(e => e.id === selectedFeatureId);
            console.log('selectedFeature :-)', this.selectedFeature);
            this.fetchMetaDataForm();
        },

        fetchMetaDataForm() {
            if (!this.selectedFeature) {
                return;
            }

            this.isLoading = true;

            api.getPageMetaData(this.websiteId, this.pageId, this.selectedFeature.id)
                .then(response => {
                    console.log('response', response);

                    if (response.status === 404) {
                        console.log('404')
                        this.currentForm = { featureId: this.featureId, groups: [], introInformation: null };
                    } else {
                        this.forms.push({ id: this.featureId, groups: response.data });
                        console.log("mdf", this.forms);

                        if (response.data !== null && typeof (response.data.groups) !== 'undefined') {
                            this.currentForm = { featureId: this.featureId, feature: this.selectedFeature.label, groups: response.data.groups, introInformation: response.data.introInformation };
                        } else {
                            this.currentForm = { featureId: this.featureId, groups: [], introInformation: null };
                        }

                        //this.currentForm.featureId = this.feature.id;
                    }

                    this.getRulesFieldData();
                }).finally(() => {
                    this.isLoading = false;
                })
        },

        getRulesFieldData() {
            var fieldDataList = [];
            // console.log("metaDataForm", this.$refs.metaDataForm.currentForm);
            if (this.currentForm.groups.length > 0) {
                var groups = this.currentForm.groups;
                groups.forEach(g => {
                    if (g.properties) {
                        this.getPropertiesFieldData(g, g.properties, fieldDataList)
                    }
                })
            }

            console.log("fieldDataList", fieldDataList);
            this.rulesFieldData = fieldDataList;
        },

        getPropertiesFieldData(group, properties, fieldDataList) {
            properties.forEach(p => {
                if (p.form) {
                    if (p.form.properties) {
                        // check if form has sub properties
                        this.getPropertiesFieldData(group, p.form.properties, fieldDataList)
                    } else {
                        // form has no properties, therefore get these properties info
                        //console.log("getPropertiesFieldData: ", p);
                        var fieldData = {};
                        fieldData.id = p.id;
                        fieldData.name = p.name;
                        fieldData.label = `${group.label} - ${p.form.label}`;
                        fieldDataList.push(fieldData);
                    }
                }
            })
        },

        onNewElementAddded(allFields) {
            console.log("met-data-page received event");
            console.log(allFields);
            var fieldDataList = [];
            if (allFields.groups.length > 0) {
                var groups = allFields.groups;
                groups.forEach(g => {
                    if (g.properties) {
                        this.getPropertiesFieldData(g, g.properties, fieldDataList)
                    }
                })
            }
            this.rulesFieldData = fieldDataList;
        },

        getFeatureId() {
            if (typeof this.selectedFeature !== 'undefined' && this.selectedFeature !== null) {
                return this.selectedFeature.id
            }

            return -1;
        },

        onAddSubFeature(subFeature, rootElement) {
            console.log("metaDatapage onAddSubFeature", subFeature, rootElement);
            this.$emit("addSubFeature", subFeature, rootElement);

            var elementId = 1;

            this.currentForm.groups.forEach(g => {
                console.log(g.id, elementId);
                g.id = elementId;
                elementId++;
                if (g.properties) {
                    elementId = this.updateElementId(elementId, g.properties)
                }
            })

            console.log('id\'s updated:', this.currentForm);
        },

        updateElementId(elementId, properties) {
            if (properties === null || typeof properties === 'undefined') {
                return elementId;
            }

            properties.forEach(p => {
              //  console.log(p.id, elementId);
                p.id = elementId;
                elementId++;

                if (p.form) {
                    if (p.form.properties) {
                        // check if form has sub properties
                        elementId = this.updateElementId(elementId, p.form.properties)
                    }

                    if (typeof p.form.components !== 'undefined') {
                        //console.log('hereaa', p.form.components);
                        p.form.components.forEach(c => {
                            c.groups.forEach(g => {
                                console.log(g.id, elementId);
                                g.id = elementId;
                                elementId++;
                                if (g.properties) {
                                    elementId = this.updateElementId(elementId, g.properties)
                                }
                            });
                        });
                    }
                }
            });

            //console.log(properties);
            if (properties.groups) {
                properties.groups.forEach(g => {
                    elementId = this.updateElementId(elementId, g.properties)
                })
            }

            return elementId;
        },



    }
}
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col">
                <h1>Page: {{ path }}</h1>
                <div class="h-100">
                    <div class="row mb-3 pb-1">
                        <div class="row">
                            <div class="col-xl-12 padding">

                                <div class="card">
                                    <div class="card-header border-0 align-items-center d-flex">
                                        <h4 class="card-title mb-0 flex-grow-1">Meta data</h4>

                                    </div>
                                    <!-- end card header -->
                                    <div class="card-body">

                                        <div class="row">
                                            <div class="col-lg-6">
                                                <select :disabled="isLoading" @change="onFeatureChanged"
                                                    v-model="selectedFeatureId" class="form-select mb-3">
                                                    <option>Select item type</option>
                                                    <option v-for="feature in features" :value="feature.id"
                                                        :key="feature.id">
                                                        {{ feature.label }}</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="row" v-show="isLoading">
                                            <div class="col-lg-6">
                                                <Spinner :isLoading="isLoading"></Spinner>
                                                <p class="text-center">Generating your data</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <!-- end card -->
                        </div>

                        <div class="row">
                            <div class="col-xl-12 padding">
                                <div v-if="selectedFeature !== null">
                                    <!--start form-->
                                    <MetaDataForm :websiteId="websiteId" :webPageId="pageId" :featureId="getFeatureId()"
                                        :existingForm="currentForm" @newElementAdded="onNewElementAddded"
                                        @addSubFeature="onAddSubFeature" />
                                    <!--end form-->


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="customizer-setting d-none d-md-block">
            <div class="btn-info btn-rounded shadow-lg btn btn-icon btn-lg p-2" data-bs-toggle="offcanvas"
                data-bs-target="#rulesCanvasRight" aria-controls="rulesCanvasRight" id="mdi-cog"><i
                    class="mdi mdi-spin mdi-cog-outline fs-22"></i></div>
        </div>


        <!-- right offcanvas -->
        <div v-if="selectedFeature" class="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false"
            tabindex="-1" id="rulesCanvasRight" aria-labelledby="offcanvasRightLabel">
            <div class="offcanvas-header">
                <h5 id="offcanvasRightLabel">Meta Data Rules</h5>
                <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body">
                <Rules v-if="selectedFeature" :fieldData="rulesFieldData" :featureObj="selectedFeature"
                    :websiteId="websiteId" :pageId="pageId"></Rules>
            </div>
        </div>
    </Layout>
</template>