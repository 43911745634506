<script>

import rulesSerivce from "./rules.js";
import ChooseField from "./components/chooseField.vue";
import ConditionsField from "./components/conditionsField.vue";
import ExpectedValueField from "./components/expectedValueField.vue";
import SetValueField from "./components/setValueField.vue";
import api from '@/services/secureHttps';
import Spinner from "@/components/spinner";

export default {
    components: {
        ChooseField,
        ConditionsField,
        ExpectedValueField,
        SetValueField,
        Spinner
    },
    emits: {
        'updateValue'(property) {
            return property;
        }
    },
    props: {
        fieldData: {
            type: Array,
            required: true,

            default(rawProps) {
                console.log("rawProps:-", rawProps);
                /*id: label*/
                return [];
            }
        },
        featureObj: {
            type: Object,
            required: true
        },
        websiteId: {
            type: Number,
            required: true
        },
        pageId: {
            type: Number,
            required: true
        }
    },

    mounted() {
        console.log("!!!!!----!!!!!");
        console.log("rule");
        console.log(rulesSerivce.rules.ruleTypes);
    },

    data() {
        const ruleComponents = {};
        ruleComponents.chooseField = ChooseField;
        ruleComponents.conditions = ConditionsField;
        ruleComponents.expectedValue = ExpectedValueField;
        ruleComponents.setValue = SetValueField;

        return {
            ruleTypes: rulesSerivce.rules.ruleTypes,
            ruleSteps: rulesSerivce.rules.steps,
            ruleOptions: [],
            ruleComponents,
            stepUserValues: [],
            stepIndex: 0,
            selectedRuleId: -1,
            canSave: false,
            isLoading: false,
            savedSuccessfully : false,
            isSavedError : false

        }
    },

    methods: {
        formatLabel(lbl) {
            lbl = lbl.replace("[feature]", this.featureObj.label);
            return lbl;
        },

        onRuleChanged(e) {

            this.ruleOptions = [];
            this.canSave = false;
            this.selectedRuleId = -1;
            this.stepIndex = 0;
            this.stepUserValues = [];
            this.savedSuccessfully = false;
            this.isSavedError = false;

            if (e.target.value === "noValue") {
                return;
            }

            this.selectedRuleId = e.target.value;
            this.getNextStep(this.stepIndex, this.selectedRuleId);
        },

        getProperties(option) {
            console.log("option:", option);
            return {
                title: option.title,
                description: option.description,
                stepInfo: option.stepInfo,
                stepId: option.stepId,
                defaultValue: option.defaultValue
            };
        },

        onStepCompleted(resultObj) {
            console.log("onStepCompleted: ", resultObj);
            this.savedSuccessfully = false;
            this.isSavedError = false;

            // store new or update existing value
            var existingValue = this.stepUserValues.find(x => x.source === resultObj.source);
            if (typeof existingValue === 'undefined') {
                this.stepUserValues.push(resultObj);
            } else {
                existingValue.value = resultObj.value;
            }

            console.log("userValues", this.stepUserValues);

            var ruleOption = this.ruleTypes.find(x => x.id === this.selectedRuleId);

            if (ruleOption.steps.length > this.stepIndex) {

                if (resultObj.source === ruleOption.steps[this.stepIndex].id) {

                    this.stepIndex++;
                    if (ruleOption.steps.length > this.stepIndex) {
                        this.getNextStep(this.stepIndex, this.selectedRuleId);
                    } else {
                        this.canSave = true;
                    }
                }
            }
        },

        getNextStep(currentStepIndex, ruleId) {
            console.log("is this showing?");
            console.log("selectedRuleId", ruleId);
            this.savedSuccessfully = false;
            this.isSavedError = false;

            var option = this.ruleTypes.find(x => x.id === ruleId);
            var currentStep = option.steps[currentStepIndex];

            console.log("step!!!: ", currentStep.step);
            console.log("ruleSteps: ", this.ruleSteps)

            var componentStep = this.ruleSteps.find(x => x.id === currentStep.step);
            console.log("componentStep: ", componentStep);

            var stepInfo = {};

            if (componentStep.form.type === "providedFields") {
                console.log("providedFields stepInfo: ", this.fieldData);
                stepInfo.options = this.fieldData;
            } else {
                stepInfo = componentStep;
                console.log("else stepInfo: ", stepInfo);
                console.log("option", option);
            }

            //console.log("stepInfo", stepInfo);
            console.log("option", option);
            console.log("currentStepIndex", currentStepIndex);

            this.ruleOptions.push({
                value: null,
                type: currentStep.step,
                title: currentStep.title,
                description: currentStep.description,
                stepInfo: stepInfo,
                stepId: option.steps[currentStepIndex].id,
                component: this.ruleComponents[currentStep.step]
            });

            console.log("ruleOptions", this.ruleOptions);
        },

        submitRule() {
            this.savedSuccessfully = false;
            this.isSavedError = false;
            this.isLoading = true;

            var ruleObj = {};
            ruleObj.websiteId = this.websiteId;
            ruleObj.pageId = this.pageId;
            ruleObj.featureId = this.featureObj.id;

            var rule = {};

            var selectedRule = this.getSelectedRule();
            console.log("SelecteRule", selectedRule);
            rule.ruleName = selectedRule.id;
            rule.steps = [];

            rule.scope = selectedRule.scope;

            this.stepUserValues.forEach(x => {

                console.log("userValue", x);
                var stepType = {};
                stepType.name = selectedRule.steps.find(s => s.id === x.source).step;

                var valueInfo = {};
                valueInfo.value = x.value;

                var step = {};
                step.stepType = stepType;
                step.valueInfo = valueInfo;

                rule.steps.push(step);
            })

            console.log("Submitting Rule: ", ruleObj);

            ruleObj.rule = rule;

            api.createWebsiteRule(ruleObj)
                .then(response => {
                    console.log("!!! response", response);
                    if (response.status >= 200 && response.status < 300) {
                        this.savedSuccessfully = true
                    }
                }).catch(() => {
                    this.isSavedError = true;
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },

        getSelectedRule() {
            return this.ruleTypes.find(x => x.id === this.selectedRuleId);
        },

        getScopeLabel(propName) {
            switch (propName) {
                case "website":
                    return "Whole site";
                case "featureOnly":
                    return `For any ${this.featureObj.label} feature`;
                case "pageOnly":
                    return 'For this page only';
            }
        },

        updateScopeValue(propName, event) {
            console.log('propeNAme', propName);
            console.log(event);
            var rule = this.getSelectedRule();

            for (const prop in rule.scope) {
                if (Object.hasOwn(rule.scope, prop)) {
                    console.log(`obj.${prop} = ${rule.scope[prop]}`);
                    if (prop == propName) {
                        rule.scope[prop] = event.target.checked;
                    } else {
                        rule.scope[prop] = false;
                    }
                }
            }
        }

    }
}
</script>
    
<template>

    <div class="row mb-4 ">
        <div class="col">
            <p>Add rules to generate meta data for your whole website automatically</p>

            <h4>Choose Rule type</h4>

            <select @change="onRuleChanged($event)" class="form-select">
                <option value="noValue">Select an option</option>
                <option v-for="ruleType in ruleTypes" :key="ruleType.id" :value="ruleType.id"> {{
                    formatLabel(ruleType.label)
                }} </option>
            </select>
            <div v-if="getSelectedRule()">
                <p class="mt-2 ms-1">{{ getSelectedRule().description }}</p>

                <h4>Rule Scope</h4>
                <div v-for="(value, propName, index) in getSelectedRule().scope" :key="index">
                    <input type="radio" name="ruleScope" class="form-check-input"
                        @change="updateScopeValue(propName, $event)" :checked="value" :id="index">
                    <label :for="index" class="ms-2">{{ getScopeLabel(propName) }}</label>
                </div>
            </div>

            <component v-for="(option, index) in ruleOptions" :key="index" :is="option.component"
                v-bind="getProperties(option)" @onStepCompleted="onStepCompleted" />

            <button v-if="canSave" @click="submitRule()" class="btn btn-outline-success">Save rule</button>
            <div>
                <Spinner :isLoading="isLoading"></Spinner>
            </div>
            <div v-show="isSavedError" class="mt-2">
                <p class="text-danger">Sorry, we could not save your rule. Please try again</p>
            </div>
            <div v-show="savedSuccessfully" class="mt-2">
                <p class="text-success">Rule saved.</p>
            </div>
        </div>


    </div>

</template>