<script>


export default {
    emits: {
        'onStepCompleted'(resultObj) {
            return resultObj;
        }
    },
    props: {
        title: {
            type: String,
            required: true
        },
        description: {
            type: String,
            required: true
        },

        stepInfo : {
            type: Object,
            required: true
        },
        stepId : {
            type: String,
            required: true
        }
    },

    mounted() {
    },

    data() {
        return {
          
        }
    },

    methods: {
        onOptionChanged(e) {
            //console.log("chooseField:", this.stepInfo.options);
            //console.log("chooseField:",  e.target.value);
            if (e.target.value !== "noValue") {
                this.$emit("onStepCompleted", {source: this.stepId, value: e.target.value});
            }
        }
    }
}
</script>
    
<template>

    <div class="row mt-4 mb-4 ">
        <div class="col">
            <h4>{{title}}</h4>
            <p v-if="description">{{description}}</p>

             <select @change="onOptionChanged($event)" class="form-select">
                <option value="noValue">Select an option</option>
                <option v-for="option in stepInfo.options" :key="option.id" :value="option.name"> {{ option.label }} </option>
            </select> 

        </div>

    </div>

</template>