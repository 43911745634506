const rulesService = {
    rules: {
        "ruleTypes": [
            {
                "id": "pagePath",
                "label": "Based on page path",
                "description": "For a path in the URL that contains a specified value. Set a value for a specified field",
                "scope": {
                    "featureOnly" : false
                },
                "steps": [
                    {
                        "id": "step_1",
                        "step": "conditions",
                        "title": "Condition",
                        "description": "Select a condition for the path"
                    },
                    {
                        "id": "step_2",
                        "step": "expectedValue",
                        "title": "Expected Path Value",
                        "description": ""
                    },
                    {
                        "id": "step_3",
                        "step": "chooseField",
                        "title": "Field to target",
                        "description": "Choose field"
                    },
                    {
                        "id": "step_4",
                        "step": "setValue",
                        "title": "Set value of field",
                        "description": ""
                    }
                ]
            },
            {
                "id": "featureField",
                "label": "For a field in any [feature] feature",
                "description": "For a specified field in this feature, set a constant value.",
                "scope": {
                    "featureOnly" : true
                },
                "steps": [
                    {
                        "id": "step_1",
                        "step": "chooseField",
                        "title": "Select target field",
                        "description": ""
                    },
                    {
                        "id": "step_2",
                        "step": "setValue",
                        "title": "Set value of field",
                        "description": ""
                    }
                ]
            },
            {
                "id": "fieldDepenencyRule",
                "label": "Set value based on another field value",
                "description": "When a field has a specifed value, then another specifed field will have a constant value.",
                "scope": {
                    "featureOnly" : false,
                    "pageOnly" : true
                },
                "steps": [
                    {
                        "id": "step_1",
                        "step": "chooseField",
                        "title": "When field",
                        "description": ""
                    },
                    {
                        "id": "step_2",
                        "step": "conditions",
                        "title": "Condition",
                        "description": "Select a condition"
                    },
                    {
                        "id": "step_3",
                        "step": "expectedValue",
                        "title": "Has Value",
                        "description": ""
                    },
                    {
                        "id": "step_4",
                        "step": "chooseField",
                        "title": "Then this field",
                        "description": ""
                    },
                    {
                        "id": "step_5",
                        "step": "setValue",
                        "title": "Will have a value of",
                        "description": ""
                    }
                ]
            }
        ],
        "steps": [
            {
                "id": "chooseField",
                "form": {
                    "type": "providedFields",
                    "label": ""
                }
            },
            {
                "id": "conditions",
                "form": {
                    "type": "options",
                    "options": [
                        {
                            "id": "equals",
                            "label": "equals",
                            "type": "option"
                        },
                        {
                            "id": "contains",
                            "label": "contains",
                            "type": "option"
                        },
                        {
                            "id": "startsWith",
                            "label": "starts with",
                            "type": "option"
                        },
                        {
                            "id": "endsWith",
                            "label": "ends with",
                            "type": "option"
                        }
                    ]
                }
            },
            {
                "id": "expectedValue",
                "form": {
                    "type": "providedValueType",
                    "label": "Expected value"
                }
            },
            {
                "id": "setValue",
                "form": {
                    "type": "providedValueType",
                    "label": "Set value"
                }
            }
        ]
    }
};

export default rulesService